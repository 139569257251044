<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Proveedores" :breadcrumbs="breadcrumbs" :dense="true" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main>
        <v-query-builder :query-map="filters" model="suppliers" :active.sync="activeFilter" />
        <!-- <v-btn color="primary" @click="dialogSupplier=true"><v-icon left>mdi-human-dolly</v-icon>Crear proveedor</v-btn> -->
      </template>
    </mini-header>
    <!-- end header -->

    <v-col cols="12" class="px-0">
      <skeleton-list v-if="loading" />
      <template v-else>
        <v-empty-state v-if="!suppliersList.length || Object.keys($route?.query).length === 0" type="suppliers" id="proveedores" :hiddenDescription="hiddenBtnEmpty">
          <template v-slot:actions>
            <template v-if="!$route.query.ordering && hiddenBtnEmpty">
              <v-btn @click="activeFilter = true" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
              <v-btn class="ml-2" @click="latestRecords()" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
            </template>
          </template>
        </v-empty-state>
        <template v-else class="text-center py-5">
          <!-- <v-data-table :headers="headers" :items="suppliersList" :items-per-page="pagination" mobile-breakpoint hide-default-header disable-sort hide-default-footer @click:row="(suppliersList) => {$router.push({name: 'SupplierRetrieveUpdate', params: {id: suppliersList.id}})}" style="cursor: pointer">
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" @sort="setSort" />
            </template>
            <template v-slot:[`item.name`]="{item}">
              <span class="text--secondary font-weight-medium">{{item.name}}</span>
            </template>
            <template v-slot:[`item.email`]="{item}">
              <template v-if="item.email">{{item.email}}</template>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>
            <template v-slot:[`item.created`]="{item}">
              <span class="text-right">{{item.created | date}}</span>
            </template>
          </v-data-table> -->
          <v-row>
            <v-col v-for="(supplier, index) in suppliersList" :key="`${supplier.id}-${index}`" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat @click="goTo(supplier.id)">
                  <v-avatar color="lightBlue" size="32">
                    <v-icon color="primary" size="17">mdi-human-dolly</v-icon>
                  </v-avatar>
                  <span class="d-block mt-3">
                    <v-sheet class="transparent" height="25">
                      <span v-if="supplier.name.length" class="fontBody--text font-weight-medium body-1 d-inline-block text-truncate" style="max-width: 162px;">{{supplier.name}}</span>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </v-sheet>
                  </span>
                  <span v-if="supplier.tax_id" class="mt-n2 d-block body-1 fontDraw--text d-inline-block text-truncate" style="max-width: 180px;">{{supplier.tax_id}}</span>
                  <span class="d-block mt-n1 body-2 text--disabled font-italic" v-else>Sin especificar</span>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <VMainPagination titleShowAll="Ver todos los proveedores" :count="count" redirect="SupplierListCreate" />
        </template>
      </template>
    </v-col>

    <!-- dialog new supplier -->
    <v-dialog v-model="dialogSupplier" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-medium">Crear proveedores</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogSupplier=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5">
          <v-list class="v-list-form-2 pa-0 transparent">
            <v-list-item class="pa-0">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-col class="pa-0">
                  <v-text-field v-model.trim="$v.newSupplier.name.$model" :error="$v.newSupplier.name.$error" outlined required single-line dense placeholder="Acme LLC" maxlength="100" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <!-- <v-list-item class="pa-0">
              <v-list-item-title>País</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-select v-model="$v.newSupplier.country.$model" :error="$v.newSupplier.country.$error" single-line :items="countriesList" item-text="name" item-value="id" hide-details dense height="30" outlined />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-title>Estado</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-select v-model="$v.newSupplier.state.$model" :error="$v.newSupplier.state.$error" :items="$store.getters['base/currentStates']" item-text="name" item-value="id" single-line hide-details dense height="30" outlined no-data-text="No existen estados para el país." />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-title>Dirección</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model.trim="$v.newSupplier.address.$model" :error="$v.newSupplier.address.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="mt-4">
              <v-list-item-title>
                <span class="text--secondary font-weight-medium">Información fiscal</span>
              </v-list-item-title>
            </v-list-item> -->
            <v-list-item class="px-0 pt-2">
              <v-list-item-title>RUT</v-list-item-title>
              <v-list-item-subtitle class="pa-0">
                <v-col class="pa-0">
                  <v-text-field v-model.trim="$v.newSupplier.tax_id.$model" :error="$v.newSupplier.tax_id.$error" v-dni="newSupplier.country" outlined single-line dense hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0 pt-2" v-if="country && country.id === 'CL'">
              <v-list-item-title>Actividad económica</v-list-item-title>
              <v-list-item-subtitle class="pa-0">
                <v-text-field v-model.trim="$v.newSupplier.tax_settings.cl_activity_description.$model" :error="$v.newSupplier.tax_settings.cl_activity_description.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <!-- <v-list-item class="mt-4">
              <v-list-item-title>
                <span class="text--secondary font-weight-medium">Información de contacto</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="mt-2">
              <v-list-item-title>Email de emisión</v-list-item-title>
              <v-list-item-subtitle class="pa-0">
                <v-col cols="8">
                  <v-text-field v-model.trim="$v.newSupplier.email.$model" :error="$v.newSupplier.email.$error" outlined required single-line dense maxlength="64" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Teléfono</v-list-item-title>
              <v-list-item-subtitle class="pa-0">
                <v-col cols="8">
                  <v-text-field v-model.trim="$v.newSupplier.phone.$model" :error="$v.newSupplier.phone.$error" outlined required single-line dense v-if="country" v-mask="`${country.phone_code} # #### ####`" maxlength="16" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Sitio web</v-list-item-title>
              <v-list-item-subtitle class="pa-0">
                <v-col cols="8">
                  <v-text-field v-model.trim="$v.newSupplier.website.$model" :error="$v.newSupplier.website.$error" outlined required single-line class="pa-0" dense  maxlength="64" :hide-details="true" />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item> -->
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogSupplier=false">Cancelar</v-btn>
          <v-btn color="primary" :loading="$store.state.suppliers.createLoader" @click="postSupplier">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new supplier -->

    <!-- dialog confirm list -->
    <v-dialog v-model="dialogConfirm" width="600" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-medium">Cambio de lista</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirm=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <span class="d-block">Al cambiar a la lista blanca, todos los documentos de estos proveedores, serán aceptados automáticamente.</span>
          <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
          <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogConfirm=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="handleList" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm list -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import supplier from '@/data/suppliersList'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength, email, url, requiredIf } from 'vuelidate/lib/validators'
import { rutValidator } from 'vue-dni'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VEmptyState from '@/components/commons/VEmptyState'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import VMainPagination from '@/components/commons/VMainPagination'

export default {
  components: {
    VQueryBuilder,
    VTableHeaders,
    VEmptyState,
    SkeletonList,
    VMainPagination,
    MiniHeader
  },
  mixins: [
    GenericViewMixin,
    ListViewMixin
  ],
  data: () => ({
    activeFilter: false,
    welcome: true,
    env: process.env?.VUE_APP_MODE ?? 'ax',
    scroll: 0,
    confirm: '',
    isAccept: false,
    dialogConfirm: false,
    currentList: [],
    current: {},
    newSupplier: {
      name: '',
      tax_id: '',
      tax_settings: {
        cl_activity_description: ''
      },
      country: null,
      state: '',
      address: '',
      email: '',
      phone: '',
      website: ''
    },
    filters: [
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        value: null,
        category: 'a',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'tax_id',
        label: 'RUT',
        value: null,
        category: 'a',
        operators: [],
        countries: ['PE', 'CL']
      }
      // {
      //   type: 'date',
      //   id: 'created',
      //   value: null,
      //   value2: null,
      //   category: 'b',
      //   label: 'Fecha de creación',
      //   operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
      // },
      // {
      //   type: 'radio',
      //   id: 'sources',
      //   value: null,
      //   label: 'Método de pago',
      //   category: 'c',
      //   choices: [{label: 'Si', value: 'True'}, {label: 'No', value: 'False'}]
      // },
      // {
      //   type: 'text',
      //   id: 'email',
      //   label: 'Email',
      //   value: null,
      //   category: 'c',
      //   operators: ['contiene']
      // },
      // {
      //   type: 'text',
      //   id: 'country__name',
      //   label: 'País',
      //   value: null,
      //   category: 'c',
      //   operators: ['contiene']
      // }
    ],
    headers: [
      { text: 'Proveedor', value: 'name', class: 'font-weight-bold', sortable: true },
      { text: 'RUC', value: 'tax_id', sortable: true },
      { text: 'Email', value: 'email', align: 'end', sortable: true },
      { text: 'Creado el', value: 'created', align: 'end', sortable: true },
      // { text: 'Lista', value: 'list', align: 'end', sortable: true },
      { text: '', value: 'actions', align: 'end'}
    ],
    dialogSupplier: false,
    loading: false,
    options: {
      white: ['Sacar de lista blanca', 'Cambiar a lista negra'],
      black: ['Sacar de lista negra', 'Cambiar a lista blanca'],
      null: ['Cambiar a lista blanca', 'Cambiar a lista negra']
    },
    breadcrumbs: {
      main: 'Proveedores',
      children: []
    }
  }),
  computed: {
    ...mapState({
      count: state => state.suppliers.suppliersCount,
      // suppliersList: state => state.suppliers.suppliersList,
      countriesList: state => state.base.countriesList
    }),
    country () {
      return this.newSupplier.country ? this.countriesList.find(country => country.id === this.newSupplier.country) : null
    },
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (!this.suppliersList.length) return false
      return false
    }
  },
  watch: {
    'newSupplier.country' () {
      this.newSupplier.state = ''
      this.newSupplier.tax_id = ''
    }
  },
  mounted () {
    this.$root.$on('isClose', () => {
      this.isClosePanel = true
    })
  },
  created () {
    this.suppliersList = supplier
    if ((typeof this.getList === 'function' && Object.keys(this.$route?.query).length)) {
      // this.getList()
    }
  },
  methods: {
    goTo (id = null) {
      this.$store.commit('base/SET_QUERY', this.$route.query)
      this.$router.push({ name: 'SupplierRetrieveUpdate', params: { id: id }})
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    handleConfirm () {
      if (['Cambiar a lista blanca', 'Cambiar a lista negra'].includes(this.currentList[0])) {
        this.dialogConfirm = true
      } else {
        this.handleList()
      }
    },
    handleList () {
      const index = this.suppliersListPE.findIndex(({id}) => id === this.currentList[1].id)
      if (['Cambiar a lista blanca', 'Cambiar a lista negra'].includes(this.currentList[0])) {
        this.suppliersListPE[index].list = this.currentList[1].list === 'white' ? 'black' : 'white'
      } else {
        this.suppliersListPE[index].list = null
      }
      this.dialogConfirm = false
    },
    async getList () {
      // this.welcome = false
      // if (this.$store.state.accounts.country !== 'PE') {
      //   if (!this.isClosePanel) {
      //     this.loading = true
      //     await this.$store.dispatch('suppliers/LIST', {
      //       resource: 'suppliers',
      //       query: this.$route.query
      //     })
      //     this.loading = false
      //   }
      //   this.isClosePanel = false
      // }
    },
    postSupplier () {
      this.$v.newSupplier.$touch()
      if (this.$v.newSupplier.$invalid) {
        return false
      }
      this.$store.dispatch('suppliers/CREATE', {
        resource: 'suppliers',
        payload: this.newSupplier
      })
      .then((response) => {
        this.$router.push({ name: 'SupplierRetrieveUpdate', params: { id: response.data.id } })
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogSupplier = false
      })
    }
  },
  validations: {
    newSupplier: {
      name: { required, maxLength: maxLength(100) },
      country: { required },
      state: { required },
      address: { required, maxLength: maxLength(64) },
      tax_id: {
        required: requiredIf(function () {
          return this.countriesList.find(country => country.id === this.newSupplier.country).has_regulation
        }),
        taxId: function (val) {
          if (this.newSupplier.country === 'CL') {
            return rutValidator(val)
          }
          return true
        },
        maxLength: maxLength(32)
      },
      tax_settings: {
        cl_activity_description: {
          required: requiredIf(function () {
            return this.newSupplier.country === 'CL'
          }),
          maxLength: maxLength(64)
        }
      },
      email: { required, email, maxLength: maxLength(254) },
      phone: { maxLength: maxLength(16) },
      website: { url }
    }
  }
}
</script>